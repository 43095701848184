// https://developer.mozilla.org/ja/docs/Web/API/WindowBase64/btoa#Unicode_Strings

export function UTF8Tobase64(str) {
  return btoa(unescape(encodeURIComponent(str)))
}

export function base64ToUTF8(str) {
  return decodeURIComponent(escape(atob(str.replace(/[^0-9A-Za-z+/=]/g, ''))))
}

export function base64ToBytes(str) {
  const bstr = atob(str.replace(/^.*,/, '').replace(/[^0-9A-Za-z+/=]/g, ''))
  const bytes = new Uint8Array(bstr.length)
  for (let i = 0; i < bstr.length; i++) {
    bytes[i] = bstr.charCodeAt(i)
  }
  return bytes
}

function autoMIMEType(filename) {
  const ext = filename.split('.').pop()
  if (ext === 'csv') {
    return 'data:application/octet-stream;base64'
  } else {
    return 'application/zip'
  }
}
export function download(data, filename, type = '') {
  if (!type) {
    type = autoMIMEType(filename)
  }
  const blob = new Blob([data], { type: type })
  if (window.navigator.msSaveBlob) {
    // IE11
    window.navigator.msSaveBlob(blob, filename)
    return
  }
  const link = document.createElement('a')
  document.body.appendChild(link)
  link.href = window.URL.createObjectURL(blob)
  link.download = filename
  link.click()
  link.remove()
}

export function downloadURL(url) {
  const link = document.createElement('a')
  document.body.appendChild(link)
  link.href = url
  link.download = 'checkpoints.zip'
  link.click()
  link.remove()
}
