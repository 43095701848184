export function getModelType(trainedAi) {
  let t
  if (typeof trainedAi === 'string') {
    t = trainedAi
  } else {
    t = trainedAi?.type
  }

  switch (t) {
    case 'DEEP':
    case 'FINETUNING_DEEP':
      return 'images'
    case 'naturalLanguage':
      return 'natural-language'
    case 'CLASSIFICATION':
      return 'classification'
    case 'REGRESSION':
      return 'regression'
    case 'TIME':
      return 'timeseries'
    case 'TIME_TRANSFORMER':
      return 'timeseries'
    case 'TIME_TRANSFORMER2':
      return 'timeseries'
    case 'CONVERT_DATASET':
      return 'convertDataset'
    case 'NO_ACCURACY':
    case 'CLUSTERFLOW':
      return 'noAccuracy'
    default:
      return 'images'
  }
}

export function setMetricsList(trainedAi, metricsDefs) {
  if (!trainedAi.metrics || Object.keys(trainedAi.metrics).length === 0)
    return []
  const res = []

  function setMetrics(metricsDefs, metrics) {
    const res = []
    for (const key in metrics) {
      if (metrics[key] === null || metrics[key] === undefined) continue
      /**
      プロジェクトテンプレートに精度指標がつくようになってから実装
      const isTemplateMetrics =
        this.project?.template?.metrics &&
        this?.project?.template?.metrics.indexOf(key) >= 0
       */
      if (metricsDefs[key]?.showList) {
        res.push({
          name: metricsDefs[key].name,
          value: metrics[key],
          percentage: metricsDefs[key].percentage,
          info: metricsDefs[key]?.info,
          image: metricsDefs[key]?.image
        })
      }
    }
    return res
  }
  for (const [index, metrics] of Object.entries(trainedAi.metrics)) {
    if (!metrics) continue
    let columnMetrics = null
    columnMetrics = setMetrics(metricsDefs, metrics)[0]
    if (!columnMetrics) continue
    if (
      trainedAi?.predictionColumns &&
      trainedAi.predictionColumns.length > 0
    ) {
      columnMetrics.columnName = trainedAi.predictionColumns[index]
    } else {
      columnMetrics.columnName = null
    }
    res.push(columnMetrics)
  }
  return res
}
