import { i18n } from '@/main'
import { loading } from './loading'
import { splitFullId } from '@/lib/misc.js'
import { setMetricsList, getModelType } from '@/lib/trainedAI.js'
import * as metricsDefsByModelType from '@/components/organisms/trained-ai-detail/metricsDefs'
import { download, base64ToBytes, downloadURL } from '@/lib/download'
import axios from 'axios'

const rpcTimeout = 10

const trainedAI = {
  namespaced: true,
  modules: {
    loading
  },
  state: {
    trainedAIs: {},
    loadingtrainedAIs: false
  },
  getters: {
    trainedAIs: (state) => state.trainedAIs,
    trainedAIError: (_state, getters) => getters['loading/error'],
    trainedAILoading: (_state, getters) => getters['loading/loading'],
    loadingtrainedAIs: (state) => state.loadingtrainedAIs
  },
  mutations: {
    SET_TRAINED_AI_LIST(state, value) {
      state.trainedAIs = value
    },
    SET_TRAINED_AI_DETAIL(state, { datasetId, value }) {
      state.trainedAIs[datasetId] = value
    },
    SET_TRAINED_AI_ERROR(state, { datasetId, value }) {
      state.trainedAIs[datasetId].error = value
    },
    SET_TRAINED_AI_LOADING_STATE(state, { datasetId, value }) {
      state.trainedAIs[datasetId].loading = value
    },
    SET_LOADING_TRAINED_AIS(state, value) {
      state.loadingtrainedAIs = value
    }
  },
  actions: {
    async remoteCall({ dispatch }, request) {
      try {
        const response = await Promise.race([
          this._vm.$sendMessageAndReceive(request),
          new Promise((resolve) => setTimeout(resolve, rpcTimeout * 1000)).then(
            () => {
              return {
                status: 'error',
                message: 'RPC_TIMEOUT'
              }
            }
          )
        ])
        if (response.status === 'error') {
          dispatch('notifyError', response)
        }
      } catch (error) {
        dispatch('notifyError', error)
      }
    },
    async fetchModelDetail({ state, commit }, { id, accountId, fullId }) {
      if (fullId) {
        [id, accountId] = splitFullId(fullId)
      }
      const modelFullId = id + '-' + accountId
      if (state.fetchingLearningResults[modelFullId]) {
        return
      }
      state.fetchingLearningResults[modelFullId] = true
      try {
        const req = {
          action: 'getLearningResults',
          modelId: id,
          accountId: accountId
        }
        const res = await this._vm.$sendMessageAndReceive(req)
        const modelInfo = res.info
        if (res.list) {
          commit('UPDATE_MODEL_RESULTS', {
            fullId: modelFullId,
            results: res.list
          })
        }
        if (modelInfo) {
          commit('UPDATE_MODEL_INFO', {
            fullId: modelFullId,
            mapping: modelInfo.mapping,
            trainConfig: modelInfo.train_config,
            modelType: modelInfo.modelType
          })
        }
        /*
        if (this.selectedModel && this.selectedModel.id === modelFullId) {
        } */
      } finally {
        state.fetchingLearningResults[modelFullId] = false
      }
    },
    async fetchModelList({ commit, dispatch }, projectId = null) {
      dispatch('loading/start')

      let modelReq = { action: 'getTrainedAIList' }
      if (projectId) {
        modelReq = Object.assign(modelReq, { projectId })
      } else {
        throw new Error('deprecated')
      }

      try {
        const res = await this._vm.$sendMessageAndReceive(modelReq)

        const modelList = {}

        res.list.forEach((model) => {
          modelList[model.id] = model
        })
        commit('SET_TRAINED_AI_LIST', modelList)
      } catch (e) {
        dispatch('loading/error', e)
      } finally {
        dispatch('loading/finish')
      }
    },
    async fetchTrainedAiDetail(
      { commit, dispatch },
      {
        id,
        columnIndex,
        threshold = 0.5,
        reversePositive = false,
        limit = 50,
        offset = 0
      }
    ) {
      const requestColumnIndex = columnIndex ?? 0

      const req = {
        action: 'getTrainedAIDetail',
        id,
        columnIndex: requestColumnIndex,
        threshold: threshold,
        reversePositive: reversePositive,
        limit: limit,
        offset: offset
      }
      try {
        const res = await this._vm.$sendMessageAndReceive(req)

        const modelType = getModelType(res.value.summary.type)
        let metricsDefs = metricsDefsByModelType[modelType](i18n.t.bind(i18n))

        if (modelType === 'images') {
          // 分類が 2 種類か、それ以上かをチェックする
          const isMultiClass = res.value.mapping.length > 2

          // 分類の種類数に応じて精度評価を変更する。
          metricsDefs = isMultiClass
            ? metricsDefsByModelType.imagesMultiClass(i18n.t.bind(i18n))
            : metricsDefsByModelType.images(i18n.t.bind(i18n))

          // 推論のときは表示したくない精度指標があるので除去
          const metricsDefsKeyList = Object.keys(metricsDefs)
          const removeAccuracyList = ['train_accuracy', 'train_loss']

          metricsDefs = metricsDefsKeyList.reduce((acc, key) => {
            if (!removeAccuracyList.includes(key)) acc[key] = metricsDefs[key]

            return acc
          }, {})
        }

        res.value.summary.metricsList = setMetricsList(
          res.value.summary,
          metricsDefs
        )
        return res.value
      } catch (e) {
        dispatch('notifyError', e)
      }
    },
    async fetchTimeseriesDetail({ commit, dispatch }, { id, targetIndex }) {
      const req = {
        action: 'getTrainedAiTimeseriesDetail',
        id: id,
        targetIndex: targetIndex
      }
      try {
        const res = await this._vm.$sendMessageAndReceive(req)
        return res.values
      } catch (error) {
        dispatch('notifyError', error)
      }
    },
    async deleteTrainedAIs({ dispatch }, ids) {
      await dispatch('remoteCall', {
        action: 'deleteTrainedAIs',
        ids
      })
      dispatch('fetchModelList')
    },
    async updateTrainedAI({ dispatch }, { id, name, description }) {
      await dispatch('remoteCall', {
        action: 'updateTrainedAI',
        id,
        name,
        description
      })
    },
    async deleteOptimization({ dispatch }, { id }) {
      try {
        const req = {
          action: 'optimizationConditionsDelete',
          optimizationConditionsId: id
        }
        await this._vm.$sendMessageAndReceive(req)
      } catch (error) {
        dispatch('notifyError', error)
      }
    },
    async fetchOptimizationResult({ dispatch }, payload) {
      const req = {
        ...payload
      }

      if (!payload?.limit) {
        req.action = 'getOptimizationResult'
      } else {
        req.action = 'getOptimizationResultByPaging'
      }

      try {
        const res = await this._vm.$sendMessageAndReceive(req)
        return res
      } catch (error) {
        dispatch('notifyError', error)
      }
    },
    async fetchOptimizationConditionsList({ dispatch }, trainedAiId) {
      const req = {
        action: 'getOptimizationConditionsList',
        trainedAiId
      }
      try {
        const res = await this._vm.$sendMessageAndReceive(req)
        return res.optimizationConditions
      } catch (error) {
        dispatch('notifyError', error)
      }
    },
    async downloadConvertDatasetResult({ dispatch }, payload) {
      const req = {
        action: 'downloadConvertDatasetResult',
        ...payload
      }
      try {
        const res = await this._vm.$sendMessageAndReceive(req)
        const file = res.file
        const ext = res.ext
        const decoded = base64ToBytes(file)
        await download(decoded, 'convert_result.' + ext)
      } catch (error) {
        dispatch('notifyError', error)
      }
    },
    async downloadOptimizationResult({ dispatch }, payload) {
      const req = {
        action: 'downloadOptimizationResult',
        ...payload
      }
      try {
        const res = await this._vm.$sendMessageAndReceive(req)
        const file = res.file
        const ext = res.ext
        const decoded = base64ToBytes(file)
        await download(decoded, 'optimization_result.' + ext)
      } catch (error) {
        dispatch('notifyError', error)
      }
    },
    async downloadTestDatasetResult({ dispatch }, payload) {
      const req = {
        action: 'downloadTestDatasetResult',
        ...payload
      }
      try {
        const res = await this._vm.$sendMessageAndReceive(req)
        const file = res.file
        const ext = res.ext
        const decoded = base64ToBytes(file)
        await download(decoded, 'test_dataset_inference_result.' + ext)
      } catch (error) {
        dispatch('notifyError', error)
      }
    },
    async updateOptimzationConditions({ dispatch }, payload) {
      const req = {
        action: 'optimizationConditionsUpdate',
        ...payload
      }
      try {
        await this._vm.$sendMessageAndReceive(req)
      } catch (error) {
        dispatch('notifyError', error)
      }
    },

    // テキストマイニングの情報取得
    async getTrainedTextMining({ dispatch }, { loadType, payload }) {
      const action =
        'getTrainedAi' + loadType.charAt(0).toUpperCase() + loadType.slice(1)
      const req = {
        action,
        ...payload
      }
      try {
        const res = await this._vm.$sendMessageAndReceive(req)
        return res.result
      } catch (error) {
        dispatch('notifyError', error)
      }
    },
    async downloadClusteringResult({ dispatch }, { type, payload }) {
      let action = 'downloadTrainedAiClusteringResult'
      if (type === 'all') {
        action = 'downloadTrainedAiClusteringResultAll'
      }
      const req = {
        action,
        ...payload
      }
      try {
        const res = await this._vm.$sendMessageAndReceive(req)
        const file = res.file
        const fileName =
          type === 'all' ? 'clustering_result_all.' : 'clustering_result.'
        const ext = res.ext
        const decoded = base64ToBytes(file)
        await download(decoded, fileName + ext)
      } catch (error) {
        dispatch('notifyError', error)
      }
    },
    async fetchClusteringResult({ dispatch }, payload) {
      const req = {
        action: 'getTrainedAiClusteringResult',
        ...payload
      }
      try {
        const res = await this._vm.$sendMessageAndReceive(req)
        return res.data
      } catch (error) {
        dispatch('notifyError', error)
      }
    },
    async fetchClusteringDistribution({ dispatch }, payload) {
      const req = {
        action: 'getTrainedAiClusteringDistribution',
        ...payload
      }
      try {
        const res = await this._vm.$sendMessageAndReceive(req)
        return res.data
      } catch (error) {
        dispatch('notifyError', error)
      }
    },
    async fetchRegressionGraph({ dispatch }, payload) {
      const req = {
        action: 'getTrainedAiResidualGraph',
        ...payload
      }
      try {
        const res = await this._vm.$sendMessageAndReceive(req)
        if (res.status === 'error') {
          throw res
        }
        return res.result
      } catch (error) {
        return error.code
      }
    },
    async fetchRegressionGraphDetail({ dispatch }, payload) {
      const req = {
        action: 'getTrainedAiResidualGraphDetail',
        ...payload
      }
      try {
        const res = await this._vm.$sendMessageAndReceive(req)
        return res.result
      } catch (error) {
        dispatch('notifyError', error)
      }
    },
    async downloadModel(_, { trainedAiId }) {
      const result = await axios.post('/api/mf/ai/model-download-url', {
        aiId: trainedAiId
      })
      if (result.status !== 200) {
        throw new Error('DOWNLOAD_FAILED')
      }
      const url = result.data.url
      downloadURL(url)
    }
  }
}

export default trainedAI
